<template>
  <!-- Generated using model-view-update.mustache -->
  <div class="update-entity">
    <div class="edit-page__header" v-if="isReady">
      <div class="wrapper_edit_forms content d-flex justify-content-between">
        <div class="zq-create-page-title-wrapper">
          <h3 class="zq-child-title-text zq-edit-title">{{ texts.editPage.title }}</h3>
        </div>
        <ActionsForEdit
          @isShowDropdown="isShowDropdown"
          :isShowUpdate="false"
        />
      </div>
      <div class="tabs_wrapper">
        <div
          @click="showTab('settings')"
          :class="[{active: tabName === 'settings', notSaved:  !isSettingsDataSaved}, tabClasses]"
        >
          Settings
          <div class="edit-tooltip-wrapper" v-if="!isSettingsDataSaved">
            <i aria-hidden="true" class="mt-2 fa fa-question-circle-o"></i>
            <div
              class="tooltip-old bs-tooltip-old-auto fade show"
              role="tooltip"
              aria-hidden="true"
              x-placement="right"
            >
              <div class="arrow" style="top: 18px;"></div>
              <div class="tooltip-old-inner">Data not saved!</div>
            </div>
          </div>
        </div>
        <div
          v-if="ruleModels.includes(model)"
          @click="showTab('rules')"
          :class="[{active: tabName === 'rules', notSaved:  !isRulesDataSaved}, tabClasses]"
        >
          Rules
          <div class="edit-tooltip-wrapper" v-if="!isRulesDataSaved">
            <i aria-hidden="true" class="mt-2 fa fa-question-circle-o"></i>
            <div class="tooltip-old bs-tooltip-old-auto fade show">
              <div class="arrow" style="top: 18px;"></div>
              <div class="tooltip-old-inner">Data not saved!</div>
            </div>
          </div>
        </div>
        <div
          v-if="rewardModels.includes(model)"
          @click="showTab('rewards')"
          :class="[{active: tabName === 'rewards', notSaved:  !isRewardsDataSaved}, tabClasses]"
        >
          Rewards
          <div class="edit-tooltip-wrapper" v-if="!isRewardsDataSaved">
            <i aria-hidden="true" class="mt-2 fa fa-question-circle-o"></i>
            <div class="tooltip-old bs-tooltip-old-auto fade show">
              <div class="arrow" style="top: 18px;"></div>
              <div class="tooltip-old-inner">Data not saved!</div>
            </div>
          </div>
        </div>
        <div
          v-if="translatableFields.length"
          @click="showTab('translations')"
          :class="[{active: tabName === 'translations', notSaved:  !isTranslationsDataSaved}, tabClasses]"
        >
          Translations
          <div class="edit-tooltip-wrapper" v-if="!isTranslationsDataSaved">
            <i aria-hidden="true" class="mt-2 fa fa-question-circle-o"></i>
            <div class="tooltip-old bs-tooltip-old-auto fade show">
              <div class="arrow" style="top: 18px;"></div>
              <div class="tooltip-old-inner">Data not saved!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-content content position-relative">
      <div v-if="tabName === 'settings' && isReady">
        <CCardHeader>
          <div class="mb-4 d-flex justify-content-between align-items-center">
            <strong class="title">Edit Settings</strong>
            <div class="message" v-if="!isSettingsDataSaved">Data not saved!</div>
            <CButton
              class="action-create-button zq--responsive-button__common"
              type="submit"
              @click="updateSettings"
            >
              Update Settings
            </CButton>
          </div>
        </CCardHeader>
        <CRow>
          <CCol col="12">
            <CCard class="zq--wizard-card">
              <CCardHeader @click="settingsCollapsed = !settingsCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">Edit Settings</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="settingsCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="settingsCollapsed" :duration="400">
                <CCardBody>
                  <CForm
                    ref="updateContest"
                    v-on:submit.prevent="updateContest"
                    novalidate
                    class="update-contest-form"
                  >
                    <FormBuilder
                      :list="formList"
                      :isCreateHeader="false"
                      @updated="updateFormData"
                      :page="{
                        title: texts.editPage.title,
                        info: descriptions,
                        mode: 'update',
                      }"
                    />
                  </CForm>
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
        <CRow v-if="dependantOnData">
          <CCol col="12">
            <CCard class="zq--wizard-card">
              <CCardHeader @click="dependantOnCollapsed = !dependantOnCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">Edit DependantOn</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="dependantOnCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="dependantOnCollapsed" :duration="400">
                <CCardBody>
                  <EditDependantOn
                    :dependantOnData="dependantOnData"
                    :isValid="true"
                    @updateDependantOnData="updateDependantOnData"
                  />
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
        <CRow  v-if="schedulingData">
          <CCol col="12">
            <CCard class="zq--wizard-card">
              <CCardHeader @click="schedulingCollapsed = !schedulingCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">Edit Scoring</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="schedulingCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="schedulingCollapsed" :duration="400">
                <CCardBody>
                  <CreateCompetitionScheduling
                    @updateSchedulingData="getSchedulingData"
                    @updateConstraints="getConstraints"
                    @resetSchedulingValidate="resetSchedulingValidate"
                    @updateSchedulingSwitch="updateSchedulingSwitch"
                    :constraintsData="constraints"
                    :schedulingData="schedulingData"
                    :isStartDate="schedulingDataValid_scheduledStartDate"
                    :isEndDate="schedulingDataValid_scheduledEndDate"
                    :isStrategy="schedulingDataValid_scoringStrategy"
                    :status="status"
                    creationType="contest"
                    :descriptions="schedulingDescriptions"
                    :competition-id="competitionId"
                  />
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
      </div>
      <PreviewSpiner v-if="!isReady" />
      <EditRules
        v-if="ruleModels.includes(model) && tabName === 'rules'"
        :entityId="entityId"
        :context="'contest'"
        :notSavedRulesData="notSavedRulesData"
        @isRulesDataSaved="setRulesDataSaved"
        @notSavedRulesData="setNotSavedRulesData"
      />
      <EditRewards
        v-if="rewardModels.includes(model) && tabName === 'rewards'"
        :entityId="entityId"
        :entityType="'Contest'"
        :notSavedTranslationsData="notSavedTranslationsData"
        :notSavedRewardsData="notSavedRewardsData"
        @isRewardsDataSaved="setRewardsDataSaved"
        @notSavedRewardsData="setNotSavedRewardsData"
      />
      <EditTranslations
        ref="editTranslations"
        v-if="modelFields.baseFields.translatableFields.length > 0 && tabName === 'translations'"
        :entityData="formData"
        :entityType="'Contest'"
        :translatableFields="translatableFields"
        :translationsData="translationsData"
        :notSavedTranslationsData="notSavedTranslationsData"
        @isTranslationsDataSaved="setTranslationsDataSaved"
        @notSavedTranslationsData="setNotSavedTranslationsData"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { cloneDeep, isEqual } from 'lodash';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import { dateFormate } from '@/utils/dateFormate';
import { formConfig, pageConfig } from "@/config";
import { contests } from '@/config/descriptions/contests';
import fieldHelpers from '@/utils/ZiqniFieldHelper.js';
import { contestsTexts } from '@/config/pageTexts/contests.json';
import contestFields from '@/generated/ziqni/store/modules/contests/fields';
import EditRules from '@/shared/components/supportModels/rules/EditRules';
import CreateCompetitionScheduling from '@/shared/components/supportModels/scheduling/CreateCompetitionScheduling';
import EditRewards from '@/shared/components/supportModels/rewards/EditRewards';
import EditTranslations from '@/shared/components/supportModels/translations/EditTranslations';
import EditDependantOn from '@/shared/components/supportModels/dependantOn/EditDependantOn';
import ActionsForEdit from '@/shared/components/ActionsForEdit';
import ClCardArrow from '@/shared/UI/ClCardArrow';
import PreviewSpiner from '@/shared/UI/Spiner';
import {competitions} from "@/config/descriptions/competitions.json";

export default {
  name: 'EditContest',
  components: {
    EditTranslations,
    ActionsForEdit,
    EditRules,
    CreateCompetitionScheduling,
    EditRewards,
    EditDependantOn,
    ClCardArrow,
    PreviewSpiner,
  },
  data() {
    return {
      model: 'contest',
      entityId: this.$route.params.id,
      entityName: '',
      descriptions: {
        ...contests.edit
      },
      texts: {
        ...contestsTexts
      },
      isShowDropdown: false,
      tabName: "settings",
      tabClasses: "btn edit-tab",
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      showCMetaInputs: false,
      showCAdjustmentFactorsInputs: false,
      formData: null,
      accountType: [],
      options: ["Slots", "Casino", "Hockey"],
      idValidate: null,
      nameValidate: null,
      typeValidate: null,
      page: pageConfig.page,
      formList: [],
      translatableFields: [],
      constraints: [],
      translationsData: {},
      modelFields : {
        ...contestFields,
      },
      status: 'Ready',
      schedulingData: {
        defaultRanking: true,
        scoreDesc: false,
        scoreFirst: false,
        ignoreTime: false,
        ignoreScore: false,
        timeDesc: false,
        round: 1,
        entrantsFromContest: [],
        strategies: {
          strategyType: "",
          rankingStrategy: {
            constraints: []
          },
          scoringStrategy: {
            limitUpdatesTo: 0,
            sumBestXOf: 0,
            lastUpdateTimeStamp: 0,
            recordTimeWhenSumReaches: 0
          }
        },
      },
      dependantOnData: null,
      rewardModels: ['achievement', 'contest'],
      ruleModels: ['achievement', 'contest'],
      settingsCollapsed: true,
      dependantOnCollapsed: false,
      schedulingCollapsed: false,
      isReady: false,
      isSettingsDataSaved: true,
      isRulesDataSaved: true,
      isRewardsDataSaved: true,
      isTranslationsDataSaved: true,
      notSavedText: 'Data not saved!',
      notSavedRulesData: {},
      notSavedTranslationsData: {},
      notSavedRewardsData: {},
      schedulingDataValid_scheduledStartDate: null,
      schedulingDataValid_scheduledEndDate: null,
      schedulingDataValid_scoringStrategy: null,
      competitionId: null
    }
  },
  computed: {
    ...mapGetters('contests', ['success', 'message', 'contests', 'contest']),
    ...mapGetters('competitions', ['competition']),
    ...mapGetters('actionTypes', ['success', 'message', 'actionType', 'actionTypes']),
    ...mapGetters('theme', ['theme']),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
    created() {
      return dateFormate(this.formData.created)
    },
    schedulingDescriptions() {
      return {
        ...competitions.edit.competition.scheduling,
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('contests', [
      'handleGetContests_item',
      'handleUpdateContest'
    ]),
    ...mapActions('actionTypes', ['handleGetActionTypes_item', 'handleGetActionTypes']),
    initialize() {
      this.handleGetActionTypes([]);
      this.handleGetContests_item([this.$route.params.id],1,0)
        .then(data => {
          routerBreadcrumbs(this.$router.currentRoute, {
            competitionId: data[0].competitionId,
            competitionName: this.competition ? this.competition.name : '',
            name: data[0].name,
            spaceName: data[0].spaceName,
          });

          this.competitionId = data[0].competitionId;
          let actionTypesIds = []
          if (data[0].actionTypeAdjustmentFactors) {
            actionTypesIds = data[0].actionTypeAdjustmentFactors.map((item) => {
              return item.actionTypeId
            })
          }

          this.query = actionTypesIds.length ? {"id": actionTypesIds.join()} : []; // just query for the ids!

          this.status = data[0].status;

          this.handleGetActionTypes_item(actionTypesIds);
          this.formList = fieldHelpers.prepareEditFormList(
            contestFields,
            this.texts.editPage,
            this.descriptions,
            this.contest
          );

          if (data[0].strategies) {
            this.schedulingData.scoreDesc = data[0].strategies.rankingStrategy.scoreDesc;
            this.schedulingData.scoreFirst = data[0].strategies.rankingStrategy.scoreFirst;
            this.schedulingData.ignoreTime = data[0].strategies.rankingStrategy.ignoreTime;
            this.schedulingData.ignoreScore = data[0].strategies.rankingStrategy.ignoreScore;
            this.schedulingData.timeDesc = data[0].strategies.rankingStrategy.timeDesc;
            this.schedulingData.strategies = {
              strategyType: data[0].strategies.strategyType,
              rankingStrategy: {
                constraints: []
              },
              scoringStrategy: {
                limitUpdatesTo: data[0].strategies.scoringStrategy.limitUpdatesTo,
                sumBestXOf: data[0].strategies.scoringStrategy.sumBestXOf,
                lastUpdateTimeStamp: data[0].strategies.scoringStrategy.lastUpdateTimeStamp,
                recordTimeWhenSumReaches: data[0].strategies.scoringStrategy.recordTimeWhenSumReaches
              }
            };
          }
          this.schedulingData.round = data[0].round;
          this.schedulingData.entrantsFromContest = data[0].entrantsFromContest ?? [];
          this.schedulingData.scheduledEndDate = data[0].scheduledEndDate;
          this.schedulingData.scheduledStartDate = data[0].scheduledStartDate;

          this.isReady = true;
        });
      this.translatableFields = contestFields.baseFields.translatableFields;
    },
    showTab(name) {
      this.tabName = name;
    },
    setRulesDataSaved(val) {
      this.isRulesDataSaved = val;
    },
    setRewardsDataSaved(val) {
      this.isRewardsDataSaved = val;
    },
    setTranslationsDataSaved(val) {
      this.isTranslationsDataSaved = val;
    },
    setNotSavedRulesData(obj) {
      this.notSavedRulesData = obj;
    },
    setNotSavedTranslationsData(obj) {
      this.notSavedTranslationsData = obj;
    },
    setNotSavedRewardsData(obj) {
      this.notSavedRewardsData = obj;
    },
    updateFormData(val) {
      if (this.formData && !isEqual(this.formData, val)) {
        this.isSettingsDataSaved = false;
      }
      this.formData = val;
    },
    updateDependantOnData(val) {
      if (!isEqual(this.dependantOnData.dependantOn, val.formData.dependantOn)) {
        let selectedIds = [];
        val.selectedData.forEach(item => selectedIds.push(item.id));
        this.dependantOnData.dependantOn = cloneDeep(val.formData.dependantOn);
        this.dependantOnData.dependantOn.must = this.dependantOnData.dependantOn.must.filter(item => {
          return selectedIds.includes(item);
        });
        this.dependantOnData.dependantOn.mustNot = this.dependantOnData.dependantOn.mustNot.filter(item => {
          return selectedIds.includes(item);
        });
        this.dependantOnData.dependantOn.should = this.dependantOnData.dependantOn.should.filter(item => {
          return selectedIds.includes(item);
        });
        this.dependantOnData.entityType = val.formData.entityType;
        this.isSettingsDataSaved = false;
      }
    },
    getSchedulingData(val) {
      this.schedulingData = val;
    },
    getConstraints(val) {
      this.constraints = val;
    },
    resetSchedulingValidate() {
      this.schedulingDataValid_scheduledStartDate = null;
      this.schedulingDataValid_scheduledEndDate = null;
      this.schedulingDataValid_scoringStrategy = null;
    },
    updateSchedulingSwitch(field, val) {
      this.schedulingData[field] = val;
    },
    updateSettings() {
      delete this.formData.statusCode;
      delete this.formData.status;
      delete this.formData.achievementLiveStatus;
      delete this.formData.spaceName;
      delete this.formData.created;
      delete this.formData.productRefId;
      delete this.formData.rewardType;
      delete this.formData.constraints;
      delete this.formData.memberRefId;
      delete this.formData.key;
      delete this.formData.competitionId;
      delete this.formData.groupStageLabel;
      delete this.formData.actualStartDate;
      delete this.formData.actualEndDate;
      delete this.formData.row;
      delete this.formData.round;
      delete this.formData.groupStage;
      delete this.formData.entrantsFromContest;

      this.formData.constraints = this.constraints;
      this.formData.scheduledStartDate = this.schedulingData.scheduledStartDate;
      this.formData.scheduledEndDate = this.schedulingData.scheduledEndDate;
      this.formData.strategies = this.schedulingData.strategies;
      this.formData.maxNumberOfEntrants = this.formData.maxNumberOfEntrants ?? 9999;

      this.handleUpdateContest({updateContestRequest: this.formData})
        .then(() => {
          this.isSettingsDataSaved = true;
        })
    }
  },
  watch: {
    contest: {
      deep: true,
      handler() {
        this.entityName = this.contest.name;
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";
.update-entity {
  height: 100%;
  .card-header {
    background-color: #f3f3f3;
    & ~ .row:first-of-type {
      margin-top: 1rem;
    }
  }
  .card {
    &.zq--wizard-card {
      background-color: var(--zq-sub-bg);
    }
  }
  .edit-tooltip-wrapper {
    display: inline-block;
    position: relative;
    .tooltip-old {
      position: absolute;
      visibility: hidden;
      top: -4px;
      left: 16px;
    }
  }
  .multiselect {
    &--disabled {
      .multiselect__single {
        background: none;
      }
    }
  }
  .fa-question-circle-o:hover ~ .tooltip-old {
    visibility: visible !important;
  }
  .message {
    color: var(--zq-warn);
  }
  .notSaved {
    color: var(--zq-warn) !important;
  }
  .form-content {
    border-top: 0;
  }
  .edit-page__header {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    background-color: var(--zq-main-bg);
  }
  .wrapper_edit_forms {
    margin-top: 20px;
    display: flex;
  }
  .edit-tab {
    width: 50%;
    height: 35px;
    cursor: pointer !important;
    border-radius: 12px 12px 0 0;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #858585;
    text-align: left;
    &.active {
      border-bottom: solid 2px #007dfb;
    }
    &.btn {
      padding: 0.375rem 1rem;
      text-align: center;
    }
  }
  .tabs_wrapper {
    height: 100%;
    max-height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    border-radius: 12px 12px 0 0;
  }
  & > .form-content {border-top: none}
  .update-entity-form {
    height: calc(100% - 50px);
    input::placeholder {
      font-style: italic;
    }
    .card {
      background-color: var(--zq-sub-bg);
    }
    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;
      .form-control.is-valid {
        padding-right: 15px;
      }
      .custom-multi-select .multiselect__select {
        opacity: 0;
      }
      .adjustment-factors-data {
        .fa-question-circle-o {
          top: 10px;
          right: -20px;
        }
      }
      .search-icon {
        position: absolute;
        width: 30px !important;
        height: 47px !important;
        right: 15px;
        top: 1px;
        padding: 4px 8px;
        text-align: center;
      }
      .editor {
        min-height: 200px;
        height: 200px;
      }
      .fa-question-circle-o {
        position: absolute;
        top: 0;
        right: -10px;
      }
      .meta-data {
        min-width: 70px;
        .fa-question-circle-o {
          top: 10px;
        }
      }
    }
  }
  @include media-breakpoint-up('768px') {
    .edit-page__header {
      padding: 0;
    }
    .wrapper_edit_forms {
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      margin-bottom: -8px;
    }
    .tabs_wrapper {
      margin: 0 3rem;
      min-width: 375px;
      width: 47%;
      max-height: 54px;
    }
    .edit-tab {
      height: 54px;
      line-height: 42px;
      cursor: pointer !important;
      &:hover {
        color: #858585;
      }
    }
  }
}
</style>
