<template>
  <div class="edit-dependant-on" v-if="isReady">
    <div v-if="!isSelectTableVisible">
      <DepResultTable
        @toSelectTable="toSelectTable"
        @resetValidation="resetDependantValidation"
        @updateCurrentTypes="updateCurrentTypes"
        @updateData="handleUpdateDependantOnData"
        :propData="editDependantOnData"
        :isValid="isValid"
        :currentTypesProps="currentTypes"
        :label="texts.createPage.dependantOn.dependantLabel"
        :buttonLabel="texts.createPage.dependantOn.buttonLabel"
        :errorMessage="texts.createPage.dependantOn.errorMessage"
        :disabled="isFieldDisabled"
        :isKey="isKey"
      />
    </div>
    <DepSelectTable
      @toResultTable="toResultTable"
      @updateSelectData="achSelectData"
      :selectedData="selectedData"
      :title="stepNumber + '.1. ' + texts.createPage.dependantOn.depSelectTableTitle"
      :fields="fields"
      :formData="formData"
      :toolTipText="descriptions.dependantOn"
      v-else
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DepSelectTable from '@/shared/components/DepTables/DepSelectTable';
import DepResultTable from '@/shared/components/DepTables/DepResultTable';
import { isAchFieldDisabled } from "@/utils/validationUtils";
import { achievements } from "@/config/descriptions/achievements";
import { checkNullItem } from "@/helpers/checkNullItem";
import { achievementsTexts } from "@/config/pageTexts/achievements.json";
import { pageConfig } from "@/config";
import { cloneDeep } from "lodash";

export default {
  name: 'EditDependantOn',
  components: {
    DepResultTable,
    DepSelectTable
  },
  props: {
    dependantOnData: Object,
    isValid: Boolean,
    status: {
      type: String,
      default: 'Ready'
    },
    stepNumber: {
      type: Number,
      default: 3
    },
    isKey: {
      type: Boolean,
      default: true
    },
    notSavedDependantOnData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      texts: {
        ...achievementsTexts
      },
      isSelectTableVisible: false,
      currentTypes: {},
      fields: [
        "select",
        "id",
        "created",
        "name",
        "description",
        "category",
        "constraints",
      ],
      formData: {
        dependantOn: {
          must: [],
          mustNot: [],
          should: [],
          shouldMatchAtLeast: null,
        }
      },
      descriptions: {
        dependantOn: achievements.list.create.dependantOn.dependantOn,
      },
      itemsPerPage: pageConfig.itemsPerPage,
      page: 1,
      editDependantOnData: {},
      isReady: false,
      selectedData: []
    }
  },
  computed: {
    isFieldDisabled() {
      return isAchFieldDisabled(this.status);
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('achievements', ['handleGetAchievements']),
    initialize() {
      this.formData.dependantOn = cloneDeep(this.dependantOnData);
      this.editDependantOnData.formData = {dependantOn: this.formData.dependantOn}

      const must = this.getCurrentTypeValue(this.dependantOnData.must, 'must');
      const mustNot = this.getCurrentTypeValue(this.dependantOnData.mustNot, 'mustNot');
      const should = this.getCurrentTypeValue(this.dependantOnData.should, 'should');
      const currentTypesValues = {...must, ...should, ...mustNot}

      const selectedIds = Object.keys(currentTypesValues)
      this.getSelectedData(selectedIds);

      this.editDependantOnData.currentTypes = currentTypesValues;

      if (Object.keys(currentTypesValues).length) {
        this.updateCurrentTypes(currentTypesValues);
      }

    },
    async getSelectedData(selectedIds) {
      if (selectedIds) {
        const data = await this.handleGetAchievements({idArray: selectedIds});
        this.editDependantOnData.selectedData = data;
        this.selectedData = data;
      }
      this.isReady = true;
    },
    toSelectTable() {
      this.isSelectTableVisible = true;
    },
    toResultTable() {
      this.isSelectTableVisible = false;
    },
    achSelectData({selectedData, formData}) {
      selectedData.map((item) => {
        item.valid = formData.dependantOn.mustNot.includes(item.id) || formData.dependantOn.must.includes(item.id) || formData.dependantOn.should.includes(item.id);
        return item;
      })
      this.editDependantOnData.selectedData = checkNullItem(selectedData);
      this.formData = formData;
      this.$emit('updateDependantOnData', {...this.editDependantOnData, formData, selectedData});
    },
    handleUpdateDependantOnData(val) {
      delete val.formData.dependantOn.dependantOn
      delete val.formData.dependantOn.entityType

      this.editDependantOnData.selectedData = val.selectedData
      this.$emit('updateDependantOnData', {...this.editDependantOnData, formData: val.formData, selectedData: val.selectedData});
    },
    resetDependantValidation() {
      this.$emit('resetDependantValidation');
    },
    updateCurrentTypes(val) {
      this.editDependantOnData.currentTypes = val;
      this.$emit('updateCurrentTypes', val);
    },
    getCurrentTypeValue(arr, typeValue) {
      return arr.reduce((accumulator, value) => {
        return {...accumulator, [value]: typeValue};
      }, {});
    },
  }
}
</script>

<style lang="scss">
  .edit-dependant-on {
    .zq--table {
      .table-responsive {
        min-height: auto;
      }
    }
  }
</style>
